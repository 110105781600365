import React, { memo, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";

import PageLayout from "../layouts/PageLayout";

const seoParams = {
    title: "A propos",
    description: "Développeur Web Full Stack, passionné autant par la technique que par l’esthétisme. Je suis basé à l’île de la Réunion.",
};

const Container = styled.article`
    display: flex;
    align-items: center;
    flex-direction: row;

    main, aside {
        flex: 1;

        &:first-child {
            margin-right: 80px;
        }
    }

    @media (max-width: 1150px) {
        display: block;

        main {
            margin-right: 0 !important;
            margin-bottom: 80px;
        }
    }
`

const LogoWrapper = styled.div`
    margin-bottom: 50px;
`

const LogoContainer = styled.div`
    display: flex;
    display: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;

    a {
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 50px;
        transition: transform .3s ease;

        &:hover {
            transform: scale(1.08) !important;
        }
        
        &:active {
            transform: scale(0.98) !important;
        }
    }
`

const APropos = memo(() => {
    const animationParent = useRef();

    useLayoutEffect(() => {
        const selector = gsap.utils.selector(animationParent);
        const animation = gsap.from(selector("h1, h2, p, a"), {
            duration: .8,
            ease: "power3.inOut",
            y: 20,
            opacity: 0,
            stagger: .1
        });

        return () => animation.kill();
    }, []);

    return (
        <PageLayout seo={seoParams}>
            <Container ref={animationParent}>
                <main>
                    <h1 className="display" style={{ lineHeight: 1.2 }}>Qui suis-je ?</h1>
                    <h2 className="h3" style={{ marginBottom: 40 }}><strong>Développeur Web Full Stack</strong>, passionné autant par la technique que par l’esthétisme. Je suis basé à l’<strong>île de la Réunion</strong>.</h2>
                    <p>Issu de cursus <strong>techniques et artistiques</strong>, et grâce à mon expérience sur le marché, je suis en mesure de suivre vos projets de la <strong>conception graphique au développement final</strong>, tout en conjuguant <strong>contraintes techniques</strong>, <strong>esthétiques et ergonomiques</strong>.<br />
                        Force de proposition, je suis capable de conseiller mes clients pour allier l'utile et l'agréable.<br /><br />

                        Orienté vers des <strong>technologies web</strong>, je conçois <strong>sites et applications</strong> en utilisant des <strong>techniques modernes, robustes et performantes</strong>, pour garantir des <strong>services de qualités</strong>.<br /><br />

                        Passionné, c'est avec enthousiasme et bonheur que je souhaite vous accompagner dans vos projets.</p>
                </main>
                <aside>
                    <LogoWrapper>
                        <p style={{ marginBottom: 20 }}>Les technologies de bases</p>
                        <LogoContainer>
                            <a href="https://www.w3.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/html5.svg"
                                    alt="Logo HTML5"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.w3.org/Style/CSS/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/css3.svg"
                                    alt="Logo CSS3"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://developer.mozilla.org/fr/docs/Web/JavaScript" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/javascript.svg"
                                    alt="Logo Javascript"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://jquery.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/jquery.svg"
                                    alt="Logo jQuery"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://httpd.apache.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/apache.svg"
                                    alt="Logo Apache"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.php.net/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/php.svg"
                                    alt="Logo PHP"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.mysql.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/mysql.svg"
                                    alt="Logo mySql"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://greensock.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/gsap.svg"
                                    alt="Logo GreenSock - GSAP"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://sass-lang.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/sass.svg"
                                    alt="Logo Sass"
                                    layout="fixed"
                                />
                            </a>
                        </LogoContainer>
                    </LogoWrapper>

                    <LogoWrapper>
                        <p style={{ marginBottom: 20 }}>Pour gérer vos contenus et produits e-commerce !</p>
                        <LogoContainer>
                            <a href="https://fr.wordpress.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/wordpress.svg"
                                    alt="Logo Wordpress"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://woocommerce.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/woocommerce.svg"
                                    alt="Logo Woocommerce"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://elementor.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/elementor.svg"
                                    alt="Logo Elementor"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://strapi.io/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/strapi.svg"
                                    alt="Logo Strapi"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://firebase.google.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/firebase.svg"
                                    alt="Logo Firebase"
                                    layout="fixed"
                                />
                            </a>
                        </LogoContainer>
                    </LogoWrapper>

                    <LogoWrapper>
                        <p style={{ marginBottom: 20 }}>Pour concevoir vos <strong>sites et applications modernes et performantes</strong> !</p>
                        <LogoContainer>
                            <a href="https://fr.reactjs.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/react.svg"
                                    alt="Logo React"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://redux.js.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/redux.svg"
                                    alt="Logo Redux"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://nodejs.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/nodejs.svg"
                                    alt="Logo NodeJs"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://git-scm.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/git.svg"
                                    alt="Logo Git"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://github.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/github.svg"
                                    alt="Logo GitHub"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://jamstack.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/jamstack.svg"
                                    alt="Logo Jamstack"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/gatsby.svg"
                                    alt="Logo GatsbyJs"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://nextjs.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/nextjs.svg"
                                    alt="Logo NextJs"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.npmjs.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/npm.svg"
                                    alt="Logo NPM"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://yarnpkg.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/yarn.svg"
                                    alt="Logo Yarn"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://webpack.js.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/webpack.svg"
                                    alt="Logo Webpack"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://vitejs.dev/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/vitejs.svg"
                                    alt="Logo ViteJs"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.electronjs.org/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/electron.svg"
                                    alt="Logo Electron"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://reactnative.dev/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/ios.svg"
                                    alt="Logo IOS - React Native"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://reactnative.dev/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/android.svg"
                                    alt="Logo Android - React Native"
                                    layout="fixed"
                                />
                            </a>
                        </LogoContainer>
                    </LogoWrapper>

                    <LogoWrapper>
                        <p style={{ marginBottom: 20 }}>Pour réaliser de beaux visuels !</p>
                        <LogoContainer>
                            <a href="https://affinity.serif.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/affinity.svg"
                                    alt="Logo Affinity Suite"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.figma.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/figma.svg"
                                    alt="Logo Figma"
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.adobe.com/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/icons/adobe.svg"
                                    alt="Logo Adobe Suite"
                                    layout="fixed"
                                />
                            </a>
                        </LogoContainer>
                    </LogoWrapper>
                </aside>
            </Container>
        </PageLayout>
    );
});

export default APropos;